import * as Sentry from "@sentry/browser";

const substringBreadcrumbs = 5120;

Sentry.init(Object.assign(sentryConfig.general, {
    integrations: [
        Sentry.browserTracingIntegration(),
        // Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.5,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    beforeBreadcrumb: function (breadcrumb, hint) {
        const excluded = ['google-analytics.com', 'mc.yandex.ru', '/_wdt/'];
        if ((breadcrumb.category === 'xhr' || breadcrumb.category === 'fetch')
            && excluded.find(entry => breadcrumb.data.url.indexOf(entry) !== -1)) {
            return null;
        }

        if (breadcrumb.category === 'xhr') {
            breadcrumb.data.response = hint.xhr.responseText.substring(0, substringBreadcrumbs);
            breadcrumb.data.request = hint.input.substring(0, substringBreadcrumbs);
        }
        if (breadcrumb.category === 'fetch') {
            breadcrumb.data.request = hint.input[1].body.substring(0, substringBreadcrumbs);
        }

        return breadcrumb;
    },
    beforeSend(event, hint) {
        const error = hint.originalException;
        if (error) {
            // noinspection OverlyComplexBooleanExpressionJS
            if (
                checkErrorSyntaxError(error)
                || checkErrorNsNotInitialized(error)
                || checkErrorInvalidStateError(error)
                || checkErrorReferenceError(error)
                || checkErrorTypeError(error)
            ) {
                return null;
            }
        }

        return event;
    },
}));
Sentry.setUser(sentryConfig.user);

function checkError(error) {
    const isNameError = error.name && error.name === 'Error';
    const isMessageError = error.message && (
        error.message === 'Method not found'
        || error.message === 'InvalidStateError: The object is in an invalid state.'
    );
    return isNameError && isMessageError;
}

function checkErrorTypeError(error) {
    const isNameError = error.name && error.name === 'TypeError';
    const isMessageError = error.message && (
        error.message.indexOf('Illegal invocation') >= 0
        || error.message.indexOf('e.domainName') >= 0
        || error.message.indexOf('Cannot read properties of') >= 0
        || error.message === 'XMLHttpRequest is not a constructor'
        || error.message === '$.cart is not a function'
        || error.message === 'window.getParams is not a function'
        || error.message === '$(...).maskDate is not a function'
        || error.message === '$(...).on(...).myDropdown is not a function'
        || error.message === '$(...).unserialize is not a function'
        || error.message === '$filters.unserialize is not a function'
        || error.message === '$.debounce is not a function'
        || error.message === 'window.getModelBadges is not a function'
    );
    return isNameError && isMessageError;
}

function checkErrorReferenceError(error) {
    const isNameError = error.name && error.name === 'ReferenceError';
    const isMessageError = error.message && (
        error.message === 'customElements is not defined'
        || error.message === '$ is not defined'
        || error.message === 'reloadList is not defined'
        || error.message === 'moment is not defined'
        || error.message === 'catalogModels is not defined'
        || error.message === 'Can\'t find variable: catalogModels'
        || error.message === 'drawProducts is not defined'
        || error.message === '$filters is not defined'
    );
    return isNameError && isMessageError;
}

function checkErrorInvalidStateError(error) {
    const isNameError = error.name && error.name === 'InvalidStateError';
    const isMessageError = error.message && error.message === 'Document is not fully active';
    return isNameError && isMessageError;
}

function checkErrorNsNotInitialized(error) {
    return error.name && error.name === 'NS_ERROR_NOT_INITIALIZED';
}

function checkErrorSyntaxError(error) {
    const isNameError = error.name && error.name === 'SyntaxError';
    const isMessageError = error.message && (
        error.message === 'Unexpected identifier'
        || error.message === 'JSON.parse: unexpected end of data at line 1 column 1 of the JSON data'
        || error.message === 'Unexpected end of JSON input'
    );
    return isNameError && isMessageError;
}
